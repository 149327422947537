import { mapState } from 'vuex';
import { otpAuthService } from '@services';
import {
  MainVisualContent,
  AgreementContent,
  DetailContent,
  AccordionContent,
  EntryPhoneNumber,
  EntryVerifyOtp
} from "@components";
import {
  ENTRY_STEP_INPUT_PHONE_NUMBER,
  SKIP_AGREEMENT_INACTIVE_FLG,
  ENTRY_STEP_INPUT_OTP,
} from '@constants';
import { auth as FireBaseAuth } from '@firebaseConfig';
import { initRecaptcha } from '@/plugins/recaptcha';

export default {
  name: 'EntryVerifyOtpAuth',
  components: {
    MainVisualContent,
    AgreementContent,
    DetailContent,
    AccordionContent,
    EntryPhoneNumber,
    EntryVerifyOtp
  },
  data() {
    return {
      openAccordionState: 0,
      hasInputPhoneNumber: false,
      inputPhoneNumber: '',
      isEntryStepInputPhoneNumber: ENTRY_STEP_INPUT_PHONE_NUMBER,
      isEntryStepInputPhoneOtp: ENTRY_STEP_INPUT_OTP,
      step: ENTRY_STEP_INPUT_PHONE_NUMBER,
      phoneNumberInvalid: false,
      messageErrors: '',
      isProcessing: false,
      disableInputPhoneNumber: true,
      lotteryUrl: '',
    }
  },
  computed: {
    ...mapState({
      entryContentSetting: 'entryContentSetting',
      topContentSetting: 'topContentSetting',
      campaignInfo: 'campaignInfo',
      campaignSetting: 'campaignSetting',
      campaignUrl: 'campaignUrl',
      campaignUrlName: 'campaignUrlName',
      utmCampaign: 'utmCampaign',
      utmMedium: 'utmMedium',
      utmSource: 'utmSource',
      serialCodeViaSMS: 'serialCodeViaSMS'
    })
  },
  methods: {
    // Get header logo url
    fullMainVisualUrl() {
      let mainVisualImageUrl = this.entryContentSetting.main_visual_image_url;
      return mainVisualImageUrl ? mainVisualImageUrl : '';
    },
    // Set Open/Close state a collapse in a accordion
    openAccordion(value) {
      // Update open state of accordion
      this.openAccordionState = value;
    },
    // Set input phone number
    setInputPhoneNumber(value) {
      // Set input phone number value
      this.inputPhoneNumber = value;
      // Reset input phone number state
      this.phoneNumberInvalid = false;

      if (value) {
        this.hasInputPhoneNumber = true;
      } else {
        this.hasInputPhoneNumber = false;
      }
    },
    // Validate phone number before submit
    validatePhoneNumberBeforeSubmit() {
      // Check valid of phone number, phone number must from 10 to 15 character
      var phoneNumber = this.inputPhoneNumber.replace(/-/g, '');

      if (phoneNumber.match(/^[0-9]{10,15}$/)) {
        return true;
      } else {
        if (this.$refs.entryPhoneNumber) {
          this.$refs.entryPhoneNumber.$refs.inputPhoneNumber.focus();
        }

        this.phoneNumberInvalid = true;
        this.messageErrors = this.$t('PHONE_NUMBER_PAGE.ERROR_MSG.INVALID_PHONE_NUMBER');

        return false;
      }
    },
    // Get main text
    getMainText() {
      if (this.step == ENTRY_STEP_INPUT_OTP) {
        return this.$t('PHONE_NUMBER_PAGE.MAIN_TEXT_INPUT_OTP');
      }

      return this.entryContentSetting?.main_text;
    },
    // Get sub text
    getSubText() {
      if (this.step == ENTRY_STEP_INPUT_OTP) {
        return this.$t('PHONE_NUMBER_PAGE.SUB_TEXT_INPUT_OTP');
      }

      return this.entryContentSetting?.sub_text;
    },
    // Handle check phone number
    async handleVerifyPhoneNumber(channelId) {
      if (this.validatePhoneNumberBeforeSubmit()) {
        this.isProcessing = true
        let phoneNumber = this.inputPhoneNumber.replace(/-/g, '');
        let payloadVerify = {
          campaign_url: this.campaignUrl,
          campaign_url_name: this.campaignUrlName,
          phone_number: phoneNumber,
          utm_campaign: this.utmCampaign,
          utm_medium: this.utmMedium,
          utm_source: this.utmSource
        }

        // Call verify phone number
        const result = await otpAuthService.verifyPhoneNumber(payloadVerify);

        if (result.success) {
          let payloadSendOtp = {
            campaign_url: this.campaignUrl,
            campaign_url_name: this.campaignUrlName,
            channel_id: channelId,
            phone_number: result.data.phone_number,
            full_phone_number: this.$helpers.addPlusSignToPhoneNumber(result.data.full_phone_number),
            resend_otp: false,
          }

          // Set lottery url to store
          this.$store.commit('setLotteryCampaignUrl', result.data.lottery_url);
          // Set entry phone number data to store
          this.$store.commit('setEntryPhoneNumber', {
            channelId: channelId,
            phoneNumber: result.data.phone_number,
            fullPhoneNumber: result.data.full_phone_number,
          });
  
          // Call send Otp to phone number
          await this.handleSendOtp(payloadSendOtp)
        } else {
          if (this.$refs.entryPhoneNumber) {
            this.$refs.entryPhoneNumber.$refs.inputPhoneNumber.focus();
          }

          this.phoneNumberInvalid = true;
          this.messageErrors = this.$t(result.error_message);
        }

        this.isProcessing = false;
      }
    },
    // Handle send OTP to phone number
    async handleSendOtp(payload) {
      // Handle ever OTP channel
      this.isProcessing = true;

      const result = await otpAuthService.sendOtp(payload);

      this.isProcessing = false;

      // Check result
      if (result.success) {
        // Move to step enter otp
        this.step++;
      } else {
        if (this.$refs.entryPhoneNumber) {
          this.$refs.entryPhoneNumber.$refs.inputPhoneNumber.focus();
        }

        this.phoneNumberInvalid = true;
        this.messageErrors = this.$t(result.error_message);
      }
    },
    // Check skip agreement for page
    isSkipAgreementFlg() {
      if (this.topContentSetting &&
        this.topContentSetting?.skip_agreement_flg == SKIP_AGREEMENT_INACTIVE_FLG
      ) {
        return true;
      }

      return false;
    }
  },
  mounted() {
    // Init Recaptcha verifier
    initRecaptcha(FireBaseAuth, 'recaptcha-container').then((res) => {
      if (res) {
        this.disableInputPhoneNumber = false; // Enable input once ReCAPTCHA is ready
      }
    }).catch(() => {});
  },
  created() {
    // Set app container style justify content center when not have main visual content
    if (!this.fullMainVisualUrl()) {
      this.$helpers.setRootElementStyle('--app-justify-content', 'center');
    }
  }
}
